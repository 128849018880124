.cl-custom-accordion {
  & .MuiFormControlLabel-root {
    margin-left: 0;
    width: 100%;
    height: 48px;
    padding-right: 10px;

    .apply-well-color-to-permits-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  & .euom-toggle-container {
    & .unit-label {
      font-size: 12px;
      padding-left: 10px;
    }

    & .radio-label span {
      font-size: 14px;
    }
  }

  & .section-title {
    &.no-checkbox-title {
      padding-left: 10px;
    }
  }

  & .MuiTouchRipple-root,
  & .expand-icon {
    &:hover {
      background: none;
    }
    &.start {
      margin-right: 3px;
    }
  }

  & .custom-accordion-section .section-header {
    border-bottom: none !important;
  }

  & .custom-accordion-section .section-content {
    border-bottom: none;
    padding: 10px 10px;
  }

  & .layer-label-with-icon {
    display: flex;

    & .layerCheckboxlbl {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 155px;
      font-size: 14px;
    }
  }
}
