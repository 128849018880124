.chart-config-modal {
  .multiple-axis-container {
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 160px;
    .axis-container {
      display: flex;
      align-items: center;

      &:nth-child(2) {
        padding-top: 20px;
      }
      .axis-text-field {
        .text-field-helper-text {
          display: flex;
          .counter {
            margin-left: auto;
          }
        }
      }

      .add-axis-button {
        padding-left: 35px;
        & button {
          width: 140px;
          &:hover {
            width: 140px;
          }
        }
        > button.tgsccl-button:disabled {
          border: 1px solid rgba(255, 255, 255, 0.1);
          color: rgba(255, 255, 255, 0.1);
        }
      }

      .axis-scale {
        padding-left: 25px;
        display: flex;
        flex-direction: row;

        label {
          padding-right: 30px;
        }
      }
    }
  }

  .x-axis-data-field-select {
    min-width: 269px;
    min-height: 40px;
  }
  .x-axis-data-picker-container {
    margin-left: 5px;
  }

  .axis-select {
    height: 30px;
    width: 140px;
  }

  .color-by-picker-container {
    display: flex;

    .color-by-range-container {
      display: flex;
      align-items: end;
      padding-left: 5px;
    }
  }
}

.economics-settings-container {
  & .section-item:not(:last-child) {
    margin-bottom: 25px;
  }

  & .economics-diplay-by-attribute-select {
    height: 40px;
  }

  & .filter-radio-button {
    padding-left: 18px;
  }
}

.autocomplete-list-config {
  & .MuiInputBase-root {
    min-height: 40px;
  }
}
