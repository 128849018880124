.well-panel-section-accordion-container {
  .dst-data-summary {
    background: rgba(39, 39, 39, 1);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin: 5px 0px;
    color: rgba(255, 255, 255, 1);
    justify-content: flex-start;
    height: 50px;
    font-size: 14px;

    &.expanded,
    &.expanded:hover {
      background-color: rgba(25, 118, 210, 1);
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
    }

    .dst-summary-info {
      display: flex;
      width: 100%;
      align-items: center;

      .title {
        width: 30%;
        text-align: left;
      }

      .formation {
        margin-left: auto;
        text-align: right;
      }

      svg {
        margin-left: 10px;
      }
    }
  }
}
